import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import colorPayment from "../../../assets/payment_color.svg";
import logo from "../../../assets/logo_jakub_pos.svg";
import { styles } from "./styles";
import payUIcon from "../../../assets/PAYU_LOGO_WHITE.png";
import penIcon from "../../../assets/edit.svg";
import { CheckOutStep, Reservation, Tab } from "src/core/types";
import { useFormValuesContext } from "src/core/context";
import { format } from "date-fns";
import { usePostReservation } from "src/core/queries/usePostReservation";
import { Controller, useForm } from "react-hook-form";
import { CheckIcon } from "src/components/atoms/icons/CheckIcon";
import { CrossIcon } from "src/components/atoms/icons/CrossIcon";
import { useGetDiscount } from "src/core/queries/useGetDiscount";
import { CheckboxComponent } from "../../atoms/Checkbox";
import VisitConditions from "src/components/atoms/VisitConditions";
import greyCard from "../../../assets/payment_gray.svg";
import styless from "./styles.module.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDiscount, setFalsePromo, setTruePromo } from "src/core/redux/actions/travelActions";
import { RootState } from "src/core/redux/store";
import { useSentAgencyForm } from "src/core/queries/useSentAgencyForm";
import { resetTicket } from "src/core/redux/actions/selectTicket";
import RecapPaymentTravel from "src/components/atoms/RecapPaymentTravel";

interface RecapPaymentProps {
  // updateCheckOutState: (newTabsState: Tab[], newPanelsState: CheckOutStep[]) => void | any;
  updateCheckOutState: any;
  step: CheckOutStep;
  panels: CheckOutStep[];
  style?: any;
  tabs: Tab[];
}

export const RecapPayment: React.FC<RecapPaymentProps> = ({
  updateCheckOutState,
  step,
  panels,
  style,
  tabs,
}) => {
  const dispatch = useDispatch();
  const loc = sessionStorage.getItem("loc");
  const reduxForm = useSelector((state: RootState) => state.travelForm);
  const reservationId = useSelector((state: RootState) => state.reservationID?.reservationID);
  const { t } = useTranslation();
  const location = useLocation();
  const isPageTravel = location.pathname === "/travel";
  const penClick = () => {
    const newState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel };
      }
      if (panel.key === "2" && location.pathname === "/travel") {
        return { ...panel, isEnabled: !panel.isEnabled };
      }
      if (panel.key === "3") {
        return { ...panel, isEnabled: !panel.isEnabled };
      }
      if (panel.key === "4") {
        return { ...panel };
      }
      return { ...panel, isEnabled: false };
    });
    updateCheckOutState(tabs, newState);
  };

  // info for bill
  const { formValues, setFormValues } = useFormValuesContext();
  const userFullName = formValues.name;
  const userEmail = formValues.email;

  const originalDateString = formValues.date;
  const parsedDate = new Date(originalDateString);
  const formattedDate = format(parsedDate, "dd. M. yyyy");
  const selectedTime = formValues.time;

  const totalTickets = sessionStorage.getItem("totalTickets");
  const totalPrice = sessionStorage.getItem("totalPrice");

  // discount form settings
  const discountForm = useForm({
    defaultValues: {
      discount: "",
      consent: false,
    },
    reValidateMode: "onChange",
    mode: "onSubmit",
  });
  const { register, handleSubmit, control, watch } = discountForm;

  useEffect(() => {
    if (formValues.send_promo) {
      dispatch(setFalsePromo());
    } else {
      dispatch(setTruePromo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.send_promo]);

  // Queries
  const {
    data: discountResponse,
    refetch,
    error: discountError,
  } = useGetDiscount({ code: watch("discount") });
  const { mutate: mutateReservation } = usePostReservation();
  const { mutate: sendTravelForm } = useSentAgencyForm();

  // Calculate sale string
  const saleString = `-${
    (Number(totalPrice) * Number(formValues.discount_percent_amount)) / 100 ||
    formValues.discount_amount ||
    0
  }`;

  // Post reservation
  const onSubmit = () => {
    if (location.pathname === "/travel") {
      sendTravelForm(reduxForm);
    } else {
      const newTabsState = tabs.map((tab: Tab) => {
        return tab;
      });

      const newState = panels.map((panel: CheckOutStep) => {
        if (panel.key === "1") {
          return { ...panel, isEnabled: false, isPen: true };
        }
        if (panel.key === "2") {
          return { ...panel, isEnabled: false, isPen: true };
        }
        if (panel.key === "3") {
          return { ...panel, isEnabled: false, isStepFinished: true, isPen: true };
        }
        if (panel.key === "4") {
          return { ...panel, isEnabled: true };
        }
        return { ...panel, isEnabled: false, isPen: false };
      });
      updateCheckOutState(newTabsState, newState);

      const reservationData: Reservation = {
        reservation: reservationId,
        order_type: formValues.order_type,
        discount: reduxForm?.discount || "",
        email: formValues.email,
        name: formValues.name,
        phone_number: Number(formValues.phone_number),
        send_promo: Boolean(formValues.send_promo),
      };
      mutateReservation(reservationData);
    }
  };

  const onSubmitDiscount = async (formData: { discount: string | null }) => {
    void refetch();
    setFormValues({ ...formValues, ...formData });
    if (formData.discount) {
      dispatch(setDiscount(formData.discount));
    }
  };

  const discountTrue = formValues.discount_amount || formValues.discount_percent_amount;
  const billStyle = discountTrue ? styless.billBig : styless.billSmall;
  const total = Number(sessionStorage.getItem("totalPrice")) || 0;

  const getFinalPriceByDiscount = (): number => {
    if ((formValues.discount || formValues.discount_amount) && total) {
      if (formValues.discount_amount && !formValues.discount_percent_amount) {
        return Number(total - formValues.discount_amount);
      } else if (!formValues.discount_amount && formValues.discount_percent_amount) {
        return Number(total - total * (formValues.discount_percent_amount / 100));
      } else {
        return total;
      }
    } else {
      return total || 0;
    }
  };
  // CheckboxComponent
  const [consentOneChecked, setConsentOneChecked] = useState<boolean>(false);
  const [consentTwoChecked, setConsentTwoChecked] = useState<boolean>(false);

  const handleConsentChange = (consentOne: boolean, consentTwo: boolean) => {
    setConsentOneChecked(consentOne);
    setConsentTwoChecked(consentTwo);
  };
  const ticketsChose = formValues.order_type;

  //mobile version
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  //middle version
  const [isMiddle, setIsMiddle] = useState(window.innerWidth < 1280 && window.innerWidth > 768);
  const updateisMiddle = () => {
    setIsMiddle(window.innerWidth < 1280 && window.innerWidth > 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateisMiddle);
    return () => window.removeEventListener("resize", updateisMiddle);
  }, []);

  const styleMiddle = {
    textField: {
      width: isMiddle ? "240px" : "340px",
      height: "40px",
      margin: "10px 0px 30px 0px",
      "& .MuiInputBase-root": {
        color: "#000000",
        borderRadius: "5px",
        border: "1px solid #E1E1E1",
        fontSize: "16px",
        backgroundColor: "#FFFFFF",
        "&.Mui-focused": {
          borderColor: "#000000",
        },
        "& .MuiOutlinedInput-root.MuiInputBase-colorPrimary": {
          backgroundColor: "#FFFFFF",
        },
        "& .MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd": {
          backgroundColor: "#FFFFFF",
        },
        "& .MuiInputAdornment-root": {
          color: "red",
        },
      },
    },
  };

  const personalCLick = () => {
    if (location.pathname === "/travel") {
      const newState = panels.map((panel: CheckOutStep) => {
        if (panel.key === "1") {
          return { ...panel, isEnabled: true, isStepFinished: false };
        }
        return { ...panel, isEnabled: false, isStepFinished: false };
      });
      const newTabState = tabs.map((tab: Tab) => {
        if (tab.key === "1") {
          return { ...tab, isTabActive: true, isTabDone: false };
        }
        return { ...tab, isTabActive: false, isTabDone: false };
      });
      updateCheckOutState({ tabs: newTabState, panels: newState });
    } else {
      const newState = panels.map((panel: CheckOutStep) => {
        if (panel.key === "1") {
          return { ...panel };
        }
        if (panel.key === "2") {
          return { ...panel };
        }
        if (panel.key === "3") {
          return { ...panel, isEnabled: true, isStepFinished: false };
        }
        return { ...panel, isEnabled: false, isStepFinished: false };
      });
      const newTabsState = tabs.map((tab: Tab) => {
        if (tab.key === "3") {
          return { ...tab, isTabActive: true, isTabDone: false };
        }
        return { ...tab, isTabActive: false, isTabDone: false };
      });
      updateCheckOutState(newTabsState, newState);
    }
  };

  const dateCLick = () => {
    sessionStorage.removeItem("time_slot_id");
    sessionStorage.removeItem("free_capacity");
    sessionStorage.removeItem("time");
    sessionStorage.removeItem("selectedSlotId");
    sessionStorage.removeItem("lastSubmittedSlotId");
    dispatch(resetTicket());
    const newState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel, isEnabled: true, isStepFinished: false };
      }
      return { ...panel, isEnabled: false, isStepFinished: false, isPen: false };
    });
    const newTabsState = tabs.map((tab: Tab) => {
      if (tab.key === "1") {
        return { ...tab, isTabActive: true, isTabDone: false };
      }
      return { ...tab, isTabActive: false, isTabDone: false };
    });
    updateCheckOutState(newTabsState, newState);
  };

  const ticketCLick = () => {
    const newState = panels.map((panel: CheckOutStep) => {
      if (panel.key === "1") {
        return { ...panel };
      }
      if (panel.key === "2") {
        return { ...panel, isEnabled: true, isStepFinished: false };
      }
      return { ...panel, isEnabled: false, isStepFinished: false };
    });
    updateCheckOutState(tabs, newState);
  };

  const getCSSWrapper = (): string => {
    if (location.pathname === "/travel" && !isMobile) {
      return styless.box_mutated;
    }
    return styless.box;
  };

  return (
    <Box style={{ width: "100vw" }}>
      {step.isEnabled ? (
        <>
          <Box className={getCSSWrapper()}>
            <Box>
              <Typography style={styles.title}>
                <img src={colorPayment} style={{ marginRight: isMobile ? "5px" : "10px" }} />
                {t("recap_and_payment")}
              </Typography>
              {!isPageTravel ? (
                <Box className={styless.wrapper}>
                  <Box className={billStyle}>
                    {isMobile ? (
                      <Box className={styless.billPartOne}>
                        <div className={styless.rowOne}>
                          <img src={logo} className={styless.logo} />
                          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                            {t("contant_button")}
                          </Typography>
                        </div>
                        <div className={styless.rowFour}>
                          <div className={styless.typography}>
                            <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                              {t("name_surname")}
                            </Typography>
                            <p onClick={personalCLick} className={styless.pen}>
                              {t("to_change")}
                            </p>
                          </div>
                          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                            {userFullName}
                          </Typography>
                        </div>
                        <div className={styless.rowFive}>
                          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                            {t("email_address")}
                          </Typography>
                          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                            {userEmail}
                          </Typography>
                        </div>
                        <div className={styless.rowSix}>
                          <div className={styless.typography}>
                            <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                              {t("date_and_time")}
                            </Typography>
                            {location.pathname !== "/travel" && (
                              <p onClick={dateCLick} className={styless.pen}>
                                {t("to_change")}
                              </p>
                            )}
                          </div>
                          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                            {`${formattedDate}, ${selectedTime.slice(0, 5)}`}
                          </Typography>
                        </div>
                      </Box>
                    ) : (
                      <Box className={styless.billPartOne}>
                        <div className={styless.rowOne}>
                          <img src={logo} className={styless.logo} />
                          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                            {t("tickets2")}
                          </Typography>
                        </div>
                        <div className={styless.rowTwo}>
                          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                            {t("amount_of_tickets")}
                          </Typography>
                          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                            {totalTickets}
                          </Typography>
                        </div>
                        <div className={styless.line}></div>
                        <div className={styless.rowThree}>
                          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                            {t("payment_overall")}
                          </Typography>
                          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                            {totalPrice !== null
                              ? `${totalPrice} ${t("cz_currency")}`
                              : `0 ${t("cz_currency")}`}
                          </Typography>
                        </div>
                        {discountTrue ? (
                          <>
                            <div className={styless.rowThree}>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "#E31340",
                                }}
                              >
                                {t("sale")}
                              </Typography>
                              <Typography
                                style={{ fontSize: "16px", fontWeight: "600", color: "#E31340" }}
                              >{`${saleString} ${t("cz_currency")}`}</Typography>
                            </div>
                            <div className={styless.rowThree}>
                              <Typography style={{ fontSize: "16px", fontWeight: "500" }}>
                                {t("final_price")}
                              </Typography>
                              <Typography style={{ fontSize: "16px", fontWeight: "500" }}>
                                {`${getFinalPriceByDiscount()} ${t("cz_currency")}`}
                              </Typography>
                            </div>
                          </>
                        ) : null}
                      </Box>
                    )}
                    {isMobile ? null : discountTrue ? (
                      <div className={styless.line4}></div>
                    ) : (
                      <div className={styless.line3}></div>
                    )}
                    {isMobile ? (
                      <Box className={styless.billPartTwo}>
                        <div className={styless.rowTwo}>
                          <div className={styless.typography}>
                            <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                              {t("amount_of_tickets")}
                            </Typography>
                            <p onClick={ticketCLick} className={styless.pen}>
                              {t("to_change")}
                            </p>
                          </div>
                          <Typography
                            style={{ fontSize: "16px", fontWeight: "400", marginBottom: "10px" }}
                          >
                            {totalTickets}
                          </Typography>
                        </div>
                        <div className={styless.line2}></div>
                        <div className={styless.rowThree}>
                          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                            {t("payment_overall")}
                          </Typography>
                          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>{`${
                            totalPrice !== null ? `${totalPrice}` : "0"
                          } ${t("cz_currency")}`}</Typography>
                        </div>
                        {discountTrue ? (
                          <>
                            <div className={styless.rowThree}>
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: "#E31340",
                                }}
                              >
                                {t("sale")}
                              </Typography>
                              <Typography
                                style={{ fontSize: "16px", fontWeight: "600", color: "#E31340" }}
                              >{`${saleString} ${t("cz_currency")}`}</Typography>
                            </div>
                            <div className={styless.rowThree}>
                              <span className={styless.discountString}>{t("final_price")}</span>
                              <span className={styless.discountString}>
                                <>{`${getFinalPriceByDiscount()} ${t("cz_currency")}`}</>
                              </span>
                            </div>
                          </>
                        ) : null}
                      </Box>
                    ) : (
                      <Box className={styless.billPartTwo}>
                        <div className={styless.titleTwo}>
                          <div className={styless.typography}>
                            <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                              {t("contant_button")}
                            </Typography>
                            <p onClick={personalCLick} className={styless.pen}>
                              {t("to_change")}
                            </p>
                          </div>
                        </div>
                        <div className={styless.rowFour}>
                          <Typography style={{ fontSize: "13px", fontWeight: "600" }}>
                            {t("name_surname")}
                          </Typography>
                          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                            {userFullName}
                          </Typography>
                        </div>
                        <div className={styless.rowFive}>
                          <Typography style={{ fontSize: "13px", fontWeight: "600" }}>
                            {t("email_address")}
                          </Typography>
                          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                            {userEmail}
                          </Typography>
                        </div>
                        <div className={styless.rowSix}>
                          <div className={styless.typography}>
                            <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                              {t("date_and_time")}
                            </Typography>
                            {location.pathname !== "/travel" && (
                              <p onClick={dateCLick} className={styless.pen}>
                                {t("to_change")}
                              </p>
                            )}
                          </div>
                          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
                            {`${formattedDate}, ${selectedTime.slice(0, 5)}`}
                          </Typography>
                        </div>
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                <RecapPaymentTravel
                  changeClick={personalCLick}
                  nameSurname={formValues.name || ""}
                  email={formValues.email || ""}
                />
              )}
            </Box>
            {isMobile ? (
              <Box className={styless.partTwo}>
                {!isPageTravel && (
                  <Typography
                    className={styless.titleThree}
                    style={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    {t("sale_kod")}
                  </Typography>
                )}

                <Box>
                  <form onSubmit={handleSubmit(onSubmitDiscount)} className={styless.rowSale}>
                    {!isPageTravel && (
                      <>
                        <Controller
                          name="discount"
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div>
                              <TextField
                                {...register("discount")}
                                onChange={onChange}
                                value={value}
                                id="outlined-basic"
                                variant="outlined"
                                error={
                                  (["INVALID", "ALREADY_USED"].includes(
                                    discountResponse?.result || "",
                                  ) &&
                                    Boolean(discountResponse?.result)) ||
                                  Boolean(error)
                                }
                                style={{ width: isMobile ? "250px" : "340px" }}
                                sx={styleMiddle.textField}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {discountResponse?.result === "OK" ? (
                                        <CheckIcon sx={{ fontSize: "14px" }} />
                                      ) : discountError || error ? (
                                        <CrossIcon sx={{ fontSize: "14px" }} />
                                      ) : null}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <div className={styless.discount_error}>
                                {discountResponse?.result === "ALREADY_USED" && (
                                  <span>{t("discount_already_used")}</span>
                                )}
                                {discountResponse?.result === "INVALID" && (
                                  <span>{t("invalid_discount")}</span>
                                )}
                              </div>
                            </div>
                          )}
                        />

                        <Button
                          type="submit"
                          style={isMobile ? styles.saleButtonDEMob : styles.saleButton}
                        >
                          {t("usage")}
                        </Button>
                      </>
                    )}
                  </form>
                </Box>

                <CheckboxComponent onConsentChange={handleConsentChange} />

                <Button
                  disabled={!consentOneChecked || !consentTwoChecked}
                  className={styless.buttonToGo}
                  sx={{
                    ...styles.buttonToGo,
                    "&:hover": {
                      backgroundColor: "#E31340",
                      color: "white",
                    },
                    "&:disabled": {
                      backgroundColor: "grey",
                      color: "white",
                    },
                  }}
                  style={{ textDecoration: "none" }}
                  onClick={onSubmit}
                >
                  {t("to_pay")} <img src={payUIcon} style={{ marginLeft: "20px" }} />
                </Button>
              </Box>
            ) : (
              <Box className={styless.partTwo}>
                {location.pathname !== "/travel" && (
                  <Box>
                    <div className={styless.desktop_show_only}>
                      <Typography
                        className={styless.titleThree}
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        {t("sale_kod")}
                      </Typography>
                    </div>
                    <form onSubmit={handleSubmit(onSubmitDiscount)} className={styless.rowSale}>
                      <div className={styless.middle_show_only}>
                        <Typography
                          className={styless.titleThree}
                          style={{ fontSize: "14px", fontWeight: "400" }}
                        >
                          {t("sale_kod")}
                        </Typography>
                      </div>
                      <Controller
                        name="discount"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <div>
                            <TextField
                              {...register("discount")}
                              onChange={onChange}
                              value={value}
                              id="outlined-basic"
                              variant="outlined"
                              error={
                                (["INVALID", "ALREADY_USED"].includes(
                                  discountResponse?.result || "",
                                ) &&
                                  Boolean(discountResponse?.result)) ||
                                Boolean(error)
                              }
                              className={styless.textField}
                              sx={styleMiddle.textField}
                              style={{ width: isMiddle ? "240px" : "340px" }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {discountResponse?.result === "OK" ? (
                                      <CheckIcon sx={{ fontSize: "14px" }} />
                                    ) : discountError || error ? (
                                      <CrossIcon sx={{ fontSize: "14px" }} />
                                    ) : null}
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <div className={styless.discount_error}>
                              {discountResponse?.result === "ALREADY_USED" && (
                                <span>{t("discount_already_used")}</span>
                              )}
                              {discountResponse?.result === "INVALID" && (
                                <span>{t("invalid_discount")}</span>
                              )}
                            </div>
                          </div>
                        )}
                      />
                      <Button
                        type="submit"
                        style={loc === "de" ? styles.saleButtonDE : styles.saleButton}
                      >
                        {t("usage")}
                      </Button>
                    </form>
                  </Box>
                )}

                <CheckboxComponent onConsentChange={handleConsentChange} />
                <Button
                  disabled={!consentOneChecked || !consentTwoChecked}
                  className={styless.buttonToGo}
                  sx={{
                    ...styles.buttonToGo,
                    "&:hover": {
                      backgroundColor: "#E31340",
                      color: "white",
                    },
                    "&:disabled": {
                      backgroundColor: "grey",
                      color: "white",
                    },
                  }}
                  style={{ textDecoration: "none", minHeight: "50px" }}
                  onClick={onSubmit}
                >
                  {t("to_pay")} <img src={payUIcon} style={{ marginLeft: "20px" }} />
                </Button>
              </Box>
            )}
          </Box>
          <div className={styless.visit_conditions_wrapper}>
            <VisitConditions />
          </div>
        </>
      ) : (
        step.isStepFinished && (
          <Box className={styless.closedBox}>
            <div className={styless.rowBox}>
              <img src={greyCard} />
              <Typography sx={{ color: "#B9B9B9", fontWeight: "600" }}>
                {t("recap_and_payment_title")}
              </Typography>
            </div>
            {step.isPen && <img className={styless.pen} src={penIcon} onClick={penClick} />}
          </Box>
        )
      )}
    </Box>
  );
};
