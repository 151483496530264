import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import {
  GROUP_ROUTE,
  HISTORY_ROUTE,
  GALLERY_ROUTE,
  PAYMENT_STATUS_ROUTE,
  PRE_RESERVATION_ROUTE,
  SCHOOL_ROUTE,
  TRAVEL_ROUTE,
  TYPE_OF_CUSTOMERS,
  PERSONS_ROUTE,
  FAQ_ROUTE,
  RECONSTRUCTION_ROUTE,
  EXPOSITION_ROUTE,
  MAP_ROUTE,
  OPEN_GALLERY,
  PRIVACY_POLICY,
  PAGE_REFUND,
  PAGE_REFUND_TICKET,
  PAGE_REFUND_OTHER,
} from "src/core/routing";

// Pages
import { PageHome } from "./PageHome";
import { PagePurchase } from "./PagePurchase";
import { PageWaitingForResult } from "./PageWaitingForResult";
import { PageErrorResult } from "./PageErrorResult";
import { PageSuccessfulResult } from "./PageSuccessfulResult";
import { PageReservarionOnlyResult } from "./PageReservarionOnlyResult";
import { PageDefaltPayment } from "./PageDefaltPayment";
import { PageSchool } from "./PageSchool";
import { PageTravel } from "./PageTravel";
import { PageGroup } from "./PageGroup";
import HomeHeader from "../molecules/Header";
import { clearFormValues, useFormValuesContext } from "src/core/context";
import PageSetTypeOfCustomers from "src/components/pages/PageSetTypeOfCustomers";
import PageHistory from "src/components/pages/PageHistory";
import PageGallery from "src/components/pages/PageGallery";
import PagePersons from "src/components/pages/PagePersons";
import PageFAQ from "src/components/pages/PageFAQ";
import PageDonate from "src/components/pages/PageDonate";
import PageExposition from "src/components/pages/PageExposition";
import { useDispatch } from "react-redux";
import { refreshReservationId } from "src/core/redux/actions/reservationActions";
import { setLanguage, setMobileStatus } from "src/core/redux/actions/mobileActions";
import { PageSuccessfullResultMobile } from "src/components/pages/PageSuccessfullResultMobile";
import PageMap from "src/components/pages/PageMap";
import PageGalleryOpened from "src/components/pages/PageGalleryOpened";
import PrivacyPolicy from "src/components/pages/PrivacyPolicy";
import PageRefund from "src/components/pages/PageRefund";
import PageRefundTicket from "src/components/pages/PageRefundTicket";
import PageRefundOther from "src/components/pages/PageRefungOther";

const Main = () => {
  const [urlSearchParams] = useSearchParams();
  const { setFormValues } = useFormValuesContext();
  const navigate = useNavigate();
  const isFromApp = sessionStorage.getItem("isApp") || null;

  const style = {
    wrapper: {
      margin: "auto",
      overflow: "hidden",
    },
  };
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/showroom") {
      document.body.style.backgroundColor = "#000";
    } else if (location.pathname === "/map") {
      document.body.style.backgroundColor = "#F8F8F8";
    } else {
      document.body.style.backgroundColor = "#fff";
    }
  }, [location]);

  useEffect(() => {
    const isApp = urlSearchParams.get("isApp");
    const language = urlSearchParams.get("language");
    if (isApp) {
      dispatch(setMobileStatus(true));
      sessionStorage.setItem("isApp", isApp);
      localStorage.setItem("isApp", isApp);
    }
    if (language) {
      dispatch(setLanguage(language));
      sessionStorage.setItem("app_language", language);
      sessionStorage.setItem("loc", language);
      localStorage.setItem("loc", language);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    setFormValues(clearFormValues);
    dispatch(refreshReservationId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const CastleFreeVisitingRef = useRef<HTMLDivElement | null>(null);
  const CastleFreeVisitingClick = () => {
    if (CastleFreeVisitingRef.current) {
      const elementPosition = CastleFreeVisitingRef.current.getBoundingClientRect().top;
      const offsetPositionDesktop = elementPosition + window.pageYOffset - 100;
      const offsetPositionMobile = elementPosition + window.pageYOffset - 70;
      window.scrollTo({
        top: isMobile ? offsetPositionMobile : offsetPositionDesktop,
        behavior: "smooth",
      });
    }
  };

  const PurchseTiketsRef = useRef<HTMLDivElement | null>(null);
  const PurchseTiketsClick = () => {
    if (PurchseTiketsRef.current) {
      const elementPosition = PurchseTiketsRef.current.getBoundingClientRect().top;
      const offsetPositionDesktop = elementPosition + window.pageYOffset - 80;
      const offsetPositionMobile = elementPosition + window.pageYOffset - 120;
      window.scrollTo({
        top: isMobile ? offsetPositionMobile : offsetPositionDesktop,
        behavior: "smooth",
      });
    }
  };
  const FooterRef = useRef<HTMLDivElement | null>(null);
  const footerClick = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    setTimeout(() => {
      if (FooterRef.current) {
        const elementPosition = FooterRef.current.getBoundingClientRect().top;
        const offsetPositionDesktop = elementPosition + window.pageYOffset - 100;
        const offsetPositionMobile = elementPosition + window.pageYOffset - 80;
        window.scrollTo({
          top: isMobile ? offsetPositionMobile : offsetPositionDesktop,
          behavior: "smooth",
        });
      }
    }, 0);
  };
  const controllPage = (): boolean => {
    return location.pathname === "/";
  };
  const VisitRef = useRef<HTMLDivElement | null>(null);
  const VisitDesktopDesktop = useRef<HTMLDivElement | null>(null);
  const visitClick = () => {
    if (!controllPage()) {
      navigate("/");
    }
    setTimeout(() => {
      if (isMobile && VisitRef.current) {
        window.scrollTo({
          top: VisitRef.current?.offsetTop + 350,
          behavior: "smooth",
        });
      } else if (VisitDesktopDesktop.current) {
        console.log(VisitDesktopDesktop.current);
        window.scrollTo({
          top: VisitDesktopDesktop.current?.offsetTop - 300,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  return (
    <Box sx={style.wrapper}>
      {!isFromApp && location.pathname !== "/showroom" && (
        <HomeHeader
          castleClick={CastleFreeVisitingClick}
          footerClick={footerClick}
          purchseTiketsClick={PurchseTiketsClick}
          VisitClick={visitClick}
          CastleFreeVisitingClick={CastleFreeVisitingClick}
        />
      )}
      <Routes>
        <Route
          index
          element={
            <PageHome
              FooterRef={FooterRef}
              PurchseTiketsClick={PurchseTiketsClick}
              VisitRef={VisitRef}
              VisitClick={visitClick}
              VisitDesktopDesktop={VisitDesktopDesktop}
            />
          }
        />
        <Route path={PRE_RESERVATION_ROUTE} element={<PagePurchase />} />
        <Route path={TYPE_OF_CUSTOMERS} element={<PageSetTypeOfCustomers />} />
        <Route path={RECONSTRUCTION_ROUTE} element={<PageDonate />} />
        <Route path={EXPOSITION_ROUTE} element={<PageExposition />} />
        <Route path={SCHOOL_ROUTE} element={<PageSchool />} />
        <Route path={TRAVEL_ROUTE} element={<PageTravel />} />
        <Route path={HISTORY_ROUTE} element={<PageHistory />} />
        <Route path={PERSONS_ROUTE} element={<PagePersons />} />
        <Route path={GALLERY_ROUTE} element={<PageGallery />} />
        <Route path={GROUP_ROUTE} element={<PageGroup />} />
        <Route path={FAQ_ROUTE} element={<PageFAQ />} />
        <Route path={MAP_ROUTE} element={<PageMap />} />
        <Route path={OPEN_GALLERY} element={<PageGalleryOpened />} />
        <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
        {/*<Route path={PAGE_REFUND} element={<PageRefund />} />*/}
        {/*<Route path={PAGE_REFUND_TICKET} element={<PageRefundTicket />} />*/}
        {/*<Route path={PAGE_REFUND_OTHER} element={<PageRefundOther />} />*/}
        <Route path={PAYMENT_STATUS_ROUTE} element={<PageDefaltPayment />}>
          <Route path=":reservationId" element={<PageWaitingForResult />} />
          <Route path=":reservationId" element={<PageErrorResult />} />
          <Route
            path=":reservationId"
            element={isFromApp ? <PageSuccessfullResultMobile /> : <PageSuccessfulResult />}
          />
          <Route path=":reservationId" element={<PageReservarionOnlyResult />} />
        </Route>
      </Routes>
    </Box>
  );
};

export default Main;
