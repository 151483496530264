import { Box } from "@mui/material";
import CheckOut from "src/components/organisms/CheckOut";
import { CountdownTimer } from "src/components/atoms/CountdownTimer";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/core/redux/store";
import Footer from "src/components/molecules/Footer";
import { useGetRemoveReservation } from "src/core/queries/useGetRemoveReservation";

export const PagePurchase = () => {
  const [timer, setTimer] = useState<boolean>(false);
  const isApp = useSelector((state: RootState) => state.isApp?.isApp);
  const [isTabTwoActive, setIsTabTwoActive] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string | null | number>(null);
  const reservationId = sessionStorage.getItem("current_reservation_id");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  //queries
  const { refetch } = useGetRemoveReservation({ reservationId: String(reservationId) });
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const PurchaseRef = useRef<HTMLDivElement | null>(null);

  const PurchaseClick = () => {
    if (PurchaseRef.current) {
      const elementPosition = PurchaseRef.current.getBoundingClientRect().top;
      const offsetPositionDesktop = elementPosition + window.pageYOffset - 100;
      const offsetPositionMobile = elementPosition + window.pageYOffset - 80;
      window.scrollTo({
        top: isMobile ? offsetPositionMobile : offsetPositionDesktop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (isApp) {
      sessionStorage.setItem("isApp", isApp);
    }
  }, [isApp]);

  useEffect(() => {
    if (currentTab !== "4" && currentTab !== 4) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        sessionStorage.setItem("unloadConfirmed", "true");
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        sessionStorage.removeItem("unloadConfirmed");
      };
    }
  }, [currentTab]);

  useEffect(() => {
    if (currentTab !== "4") {
      const isUnloadConfirmed = sessionStorage.getItem("unloadConfirmed");
      if (isUnloadConfirmed === "true") {
        void refetch();
      }
    }
  }, [currentTab, refetch]);

  return (
    <Box>
      {timer && !isTabTwoActive && <CountdownTimer />}
      <CheckOut
        setTimer={setTimer}
        ref={PurchaseRef}
        setIsTabTwoActive={setIsTabTwoActive}
        setCurrentTab={setCurrentTab}
      />
      <Footer />
    </Box>
  );
};
